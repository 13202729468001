import { useState, useEffect } from "react";

type UseRotateCountArgs = {
  count: number;
  interval?: number;
};

export function useRotateCount({ count, interval = 1000 }: UseRotateCountArgs) {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const id = setTimeout(() => {
      setCurrent((current + 1) % count);
      // console.log(current);
    }, interval);
    return () => {
      clearTimeout(id);
    };
  }, [current, interval, count]);

  return current % count;
}

type UseRotateItemsArgs<T> = {
  items: T[];
  interval?: number;
};

export function useRotateItems<T>({ items, interval }: UseRotateItemsArgs<T>) {
  const current = useRotateCount({ count: items.length, interval });

  return items[current % items.length];
}
