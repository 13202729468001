import React from "react";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import * as styles from "./BenefitList.module.scss";
import { Section } from "../Section";

type BenefitListProps = {
  className?: string;
};

export const BenefitList: React.FC<BenefitListProps> = ({
  children,
  className,
}) => {
  const [ref, inView] = useInView();
  return (
    <ul
      ref={ref}
      className={cn(className, styles.benefits, "show-up", {
        visible: inView,
      })}
    >
      {children}
    </ul>
  );
};

type BenefitProps = {
  title: React.ReactNode;
};

export const Benefit: React.FC<BenefitProps> = ({ title, children }) => {
  return (
    <li>
      <h3>{title}</h3>
      {children}
    </li>
  );
};

type ServicesBenefitsListProps = {
  className?: string;
};

export const ServicesBenefitsList: React.FC<ServicesBenefitsListProps> = ({
  children,
  className,
}) => {
  return <ul className={cn(styles.servicesBenefits, className)}>{children}</ul>;
};

type ServicesBenefitProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  image?: React.ReactNode;
};

export const ServicesBenefit: React.FC<ServicesBenefitProps> = ({
  title,
  children,
  image,
}) => {
  return (
    <li className={styles.item}>
      <div className={styles.circle}>{image}</div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        {children}
      </div>
    </li>
  );
};

type ServicesBenefitsWrapperProps = {
  children: React.ReactNode;
};
export const ServicesBenefitsWrapper: React.FC<ServicesBenefitsWrapperProps> =
  ({ children }) => {
    return (
      <div className={styles.outer}>
        <Section className={styles.section}>{children}</Section>
      </div>
    );
  };
