import React from "react";
import { Layout } from "../../../components/Layout";
import { Seo } from "../../../components/Seo";
import { PageHero } from "../../../components/PageHero";
import { Container, Section, SectionTitle } from "../../../components/Section";
import { BenefitList, Benefit } from "../../../components/BenefitList";
import { PageTitle } from "../../../components/PageHeader";
import * as styles from "./SearchInUpdatesApp.module.scss";
import { Link } from "../../../components/Link";
import { StaticImage } from "gatsby-plugin-image";
import { useRotateItems } from "../../../hooks/useRotateItems";
import ogImage from "./og_image.png";

const storeUrl =
  "https://auth.monday.com/oauth2/authorize?client_id=f79599c9c1d4e4dad182d63b9f2264fb&response_type=install";
// const storeUrl = "#";
const supportUrl =
  "https://forms.monday.com/forms/87a53de3919eb752956f42ffd4cab606";

const Page: React.FC = () => {
  return (
    <Layout noContact noHeader noFooter contactUrl={supportUrl}>
      <Seo
        ogFullTitle
        title="Search in Updates monday.com App"
        description="Text search for updates in scope of single item"
        ogImage={ogImage}
        maxImagePreview="large"
      />
      <Container>
        <PageHero
          pageTitle={
            <PageTitle>
              <strong>Search in Updates</strong> monday.com App
            </PageTitle>
          }
          image={<PageHeroImage />}
          pageCta={
            <div>
              <Link ext to={storeUrl}>
                <img
                  alt="Add to monday.com"
                  height="52"
                  src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                />
              </Link>
            </div>
          }
        >
          <p>
            Looking through dozens of updates can be really daunting because
            there's no search functionality built-in.
          </p>
          <p>
            "Search in Updates" solves this problem by implementing full text
            search for item's updates. It also conveniently highlights all the
            matches.
          </p>
        </PageHero>
      </Container>
      <Section>
        <SectionTitle>Features</SectionTitle>
        <BenefitList>
          <Benefit title="Filter threads by people involved" />
          <Benefit title="Filter updates by date range" />
          <Benefit title="Rich text support" />
          <Benefit title="Highlight matches" />
          <Benefit title="Partial and case-insensitive matching" />
          <Benefit title="Search in scope of a single item" />
        </BenefitList>
      </Section>
      <QuickStart />
    </Layout>
  );
};

const PageHeroImage: React.FC = () => {
  const images = [
    <StaticImage
      src="./hero1.png"
      alt="Automatically copy mirror columns to regular ones"
      width={546}
      quality={95}
      placeholder="none"
      formats={["auto", "png"]}
    />,
    <StaticImage
      src="./hero2.png"
      alt="Supports Numbers/Timeline/People/Status column types"
      width={546}
      quality={95}
      placeholder="none"
      formats={["auto", "png"]}
    />,
    <StaticImage
      src="./hero3.png"
      alt="Supports Mirrored columns in subitems"
      width={546}
      quality={95}
      placeholder="none"
      formats={["auto", "png"]}
    />,
    <StaticImage
      src="./hero4.png"
      alt="Supports Mirrored columns in subitems"
      width={546}
      quality={95}
      placeholder="none"
      formats={["auto", "png"]}
    />,
  ];

  const current = useRotateItems({ items: images, interval: 5000 });
  return (
    <>
      {images.map((x, i) => (
        <div key={i} style={x === current ? undefined : { display: "none" }}>
          {x}
        </div>
      ))}
    </>
  );
};

const QuickStart: React.FC = () => (
  <Section id="quick-start">
    <SectionTitle>Quick Start</SectionTitle>
    <h3>Installation</h3>
    <p>To install the app, open any item on the board.</p>
    <ol className="list">
      <li>
        Click the <strong>"Add View"</strong> button.
        <StaticImage
          src="./installation-1.png"
          alt="Add view"
          quality={95}
          className={styles.image}
          formats={["auto", "webp"]}
        />
      </li>
      <li>
        Find the <strong>Search in Updates</strong> app and click{" "}
        <strong>Add to item</strong> button.
        <br />
        <StaticImage
          src="./installation-2.png"
          alt="Add to item"
          quality={95}
          formats={["auto", "webp"]}
          className={styles.image}
        />
      </li>
    </ol>
    <h3>Usage</h3>
    <ol className="list">
      <li>
        Select <strong>Search in Updates</strong> tab to use the search
        functionality.
        <StaticImage
          src="./usage-1.png"
          alt="Search in Updates tab"
          className={styles.image}
          quality={95}
          formats={["auto", "webp"]}
        />
      </li>
      <li>
        <strong>Filter by date</strong> by clicking on the{" "}
        <strong>calendar icon</strong> and selecting a date range.
        <StaticImage
          src="./usage-2.png"
          alt="Status mirror colunm settings"
          className={styles.image}
          quality={95}
          formats={["auto", "webp"]}
        />
      </li>
      <li>
        <strong>Filter by person</strong> by clicking on the{" "}
        <strong>user icon</strong> and selecting thread participants you are
        looking for.
        <StaticImage
          src="./usage-3.png"
          alt="Filter by person"
          className={styles.image}
          quality={95}
          formats={["auto", "webp"]}
        />
      </li>
    </ol>
  </Section>
);

export default Page;
